.home{
  .fireworks-container{
    background: $gb-blue;
    background-image: url('../../../images/promozione.jpg');
    background-image: url('../../../images/promozione.jpg'), linear-gradient(0deg, $blue, $black 50%);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100vh;
    display: block;
    h1{
      position: absolute;
      top: 21%;
      text-align: center;
      width: 100%;
      font-size: 10vw;
      color: $gb_orange;
      text-shadow: 4px 4px $black;
      font-family: 'Luckiest Guy', cursive;
    }
  }
  #navbar-2{
    background: $gb-blue !important;
  }
  .level-1{
    .gb-title{
      background-color: $gb-blue;
      border: none;
      .card-body{
        padding: 0;
        h1{
          margin: 0;
        }
        .titles-link{
          color: #fff;
          display: inline-block;
          &:hover{
            color: $gb-orange;
            text-decoration: none;
          }
        }
      }
    }
    .evidence{
      background: linear-gradient(to bottom, $gb-blue, #007bff);
      .card-body{
        width: max-content;
        max-width: 90vw;
        margin: auto;
        background: $gb-orange;
        border-radius: 5px;
        padding-left: 4rem;
        padding-right: 4rem;
        h3{
          a{
            color: red;
            text-shadow: $black 2px 1px;
            font-weight: 600;
            letter-spacing: 2px;
            padding: 0.5rem;
            margin-bottom: 0.5rem;
            border-color: red;
            border-width: 3px;
            border-style: solid;
            display: inline-block;
            &:hover{
              text-decoration: none;
              box-shadow: 1px 1px $black;
            }
          }
        }
        h4{
          color: #fff;
          font-size: 1.6rem;
          text-shadow: $black 1px 1px;
          font-weight: 600;
        }
      }
    }
    @media (max-width: map_get($grid-breakpoints, sm)) {
      .gb-title{
        .card-body{
          padding: 0;
          h1{
            font-size: 1.4rem;
          }
          h2{
            display: none;
          }
        }
      }
      .evidence{
        .card-body{
          padding-left: 1rem;
          padding-right: 1rem;
          h3{
            font-size: 1rem;
          }
          h4{
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  .home-sponsors-promo{
    margin-bottom: 0.5rem;
    button{
      margin-left: 1.5rem;
      position: relative;
      font-size: 0.8rem;
      img{
        position: absolute;
        left: -35px;
        width: 50px;
      }
    }
    #sponsor_promo_wrapper{
      padding: 0.2rem;
      overflow: hidden;
      .sponsor{
        padding: 0.5rem;
        margin: 0.2rem;
        float: left;
        overflow: hidden;
        height: 120px;
        max-width: 160px;
        background-color: $gb-blue;
        display: flex;
        align-items: center;
        img{
          width: 100%;
        }
        span{
          background: rgba(255,255,255, 0.1);
          padding: 5px;
        }
        &:hover{
          background-color: $gb-orange;
          color: $gray-900;
        }
      }
    }
  }
}