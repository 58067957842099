:root{
  body{
    &.admin{
      background: #fff;
      font-size: 0.8rem;
      .modal-custom-xl{
        max-width: 1200px;
      }
      .izi-copy{
        .iziToast-target{
          top: -60px;
          left: 30px;
        }
      }
    }
  }
}
