.add-blocks-toolbar, .form-fixed-actions{
  position: fixed;
  background-color: $gb-orange;
  padding: 5px;
  z-index: 999;
  .btn{
    margin: 3px;
    padding: 5px;
  }
}

.add-blocks-toolbar{
  left: 3px;
  top: 50%;
  width: 60px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  .btn{
    font-size: 0.8rem;
  }
}

.form-fixed-actions{
  right: 3px;
  bottom: 50%;
  width: 55px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: right;
  .btn{
    font-size: 1.2rem;
  }
  a.btn{
    padding: 5px 8px;
    margin-bottom: 10px;
  }
}

@media (max-width: map_get($grid-breakpoints, sm)) {
  .add-blocks-toolbar{
    width: 38px;
    padding: 3px;
    .btn{
      margin: 3px 0;
      font-size: 0.6rem;
      padding: 3px;
    }
  }
  .form-fixed-actions{
    width: 38px;
    .btn{
      font-size: 0.9rem;
    }
    a.btn{
      padding: 5px 7px;
      margin-bottom: 10px;
    }
  }
}
