@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

@import "~bootstrap";
@import "~izitoast/dist/css/iziToast.min";
@import "~trix";
@import "actiontext";
@import "select2";
@import "pickadate/default";
@import "pickadate/default.date";
@import "gemonabasket";
