.donations{
  #navbar-2{
    display: none !important;
  }
  .donations-panel{
    .nav-link{
      font-size: 0.85rem;
      padding: 0.3rem 0.5rem;
      width: 5.7rem;
      &.active{
        background: $yellow;
        font-weight: 700;
        color: $black;
      }
    }
    .tab-options-wrapper{
      .price{
        font-size: 3rem;
        .caption{
          font-size: 1rem;
        }
      }
      .custom-donation{
        .custom-amount{
          width: 5rem;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .nav-link{
        font-size: 1.5rem;
        padding: 1rem 2rem;
        width: auto;
      }
    }
    @include media-breakpoint-only(md) {
      .nav-link{
        font-size: 1.2rem;
        padding: 0.5rem 1rem;
        width: auto;
      }
    }
    @include media-breakpoint-only(sm) {
      .nav-link{
        font-size: 1rem;
        padding: 0.5rem 1rem;
        width: auto;
      }
    }
  }
  &.success{
    .wrapper{
      background-image: url("../../../images/donation_thanks.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 100vh;
      text-align: center;
      .title{
        padding: 0.8rem;
        font-weight: bold;
        font-size: 3.5rem;
        text-shadow: 1px 1px $black;
        max-width: 30rem;
        margin: auto;
        color: $white;
      }
      @include media-breakpoint-down(sm) {
        .title{
          font-size: 2.5rem;
        }
      }
    }
    footer{
      margin-top: 0;
    }
  }
  &.cancel_subscription{
    .wrapper{
      background-image: url("../../../images/donation_thanks.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 100vh;
      text-align: center;
      .title{
        padding: 0.8rem;
        font-weight: bold;
        font-size: 2.5rem;
        text-shadow: 1px 1px $black;
        max-width: 30rem;
        margin: auto;
        color: $white;
      }
      @include media-breakpoint-down(sm) {
        .title{
          font-size: 1.5rem;
        }
      }
    }
    footer{
      margin-top: 0;
    }
  }
  &.cancel_subscription_confirm{
    .wrapper{
      background-image: linear-gradient(rgba(255,255,255,.85), rgba(255,255,255,.85)), url("../../../images/logo.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left;
      height: 100vh;
      text-align: center;
      .cancel-confirm{
        padding: 0.8rem;
        font-size: 1.3rem;
        max-width: 30rem;
        margin: auto;
        color: $black;
      }
      @include media-breakpoint-down(sm) {
        .cancel-confirm{
          font-size: 1rem;
        }
      }
    }
    footer{
      margin-top: 0;
    }
  }
}
#navbar-2{
  .donations-link{
    font-size: 0.9rem;
    background: $yellow;
    font-weight: 700;
    color: $black;
    text-transform: uppercase;
    box-shadow: -2px 2px 4px $black;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 2px;
    &:hover{
      background-color: $gb-orange;
      text-decoration: none;
    }
    @include media-breakpoint-up(sm){
      padding: 12px 29px;
      font-size: 1.2rem;
    }
  }
}