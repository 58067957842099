.rich_text_area{
  .rich-text-wrapper{
    &.caption{
      trix-toolbar{
        button[data-trix-attribute='small'],
        button[data-trix-attribute='heading2'],
        button[data-trix-attribute='heading3'],
        button[data-trix-attribute='bullet'],
        button[data-trix-attribute='number']{
          display: none;
        }
      }
    }
  }
}