body.devise_custom{
  background-image: url('../../../images/vasto.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  background-attachment: fixed;
  background-position: center;
  main.main{
    .card{
      background: rgba(255,255,255, 0.7);
      .card-body{
        img{
          &.logo{
            width: 110px;
          }
        }
      }
    }
  }
  &.devise_custom-registrations{
    .form-control{
      &.boolean{
        width: 1.5rem;
      }
      overflow: auto;
    }
  }
}