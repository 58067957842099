.inscriptions{
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url("../../../images/via_dante.jpg");
  #navbar-2{
    display: none !important;
  }
  h1{
    .badge{
      white-space: normal;
    }
  }
  #accordionInscriptions{
    & > .card{
      background: #fff;
      .card-header{
        background: $dark-grey;
        a{
          color: $black;
          text-decoration: none;
          &:hover{
            color: $prima-squadra;
            span{
              background: $prima-squadra !important;
              color: $white !important;
            }
          }
        }
      }
      .payment-status{
        font-size: 2em;
      }
    }
  }
  .card{
    background: $form-bg;
    &.public-index{
      background: transparent;
      .block{
        box-shadow: $box-shadow-lg;
        padding: 0.8rem;
        a{
          font-size: 1.2rem;
        }
      }
    }
    form{
      h4{
        margin-bottom: 1rem;
      }
      .form-group{
        //margin-bottom: 0.5rem;
        label{
          font-size: 0.8rem;
          margin-bottom: 0;
          color: #555;
        }
        input, select{
          font-size: 1rem;
          padding: 2px 5px;
        }
        select.select{
          height: 2rem;
        }
      }
    }
  }
  @media (max-width: map_get($grid-breakpoints, sm)) {
    main{
      &.main{
        h1{
          font-size: 2rem;
        }
      }
    }
  }
  #accordionInscriptions{
    .new-inscription{
      background: $gb-blue;
      padding: 2rem 1rem;
      margin: 2rem 1rem;
      text-align: center;
      box-shadow: 2px 2px $text-light;
      .info{
        margin-bottom: 0.5rem;
        color: #fff;
        font-weight: 500;
      }
    }
  }
  &.stop_medical_alert{
    .wrapper{
      background-image: url("../../../images/donation_thanks.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 100vh;
      text-align: center;
      .title{
        padding: 0.8rem;
        font-weight: bold;
        font-size: 2.5rem;
        text-shadow: 1px 1px $black;
        max-width: 40rem;
        margin: auto;
        color: $white;
      }
      @include media-breakpoint-down(sm) {
        .title{
          font-size: 1.5rem;
        }
      }
    }
    footer{
      margin-top: 0;
    }
  }
}

.admin-inscriptions{
  .seasons-wrapper{
    .badge{
      font-size: 2.5rem;
      cursor: default;
    }
  }
}
