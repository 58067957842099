body.admin-users{
  &.index{
    .search{
      position: relative;
      .q_is_deleted_true{
        position: absolute;
        right: 1rem;
        top: 7rem;
        font-size: 0.8rem;
      }
    }
  }
  .avatar{
    img{
      width: 100%;
    }
  }
}
