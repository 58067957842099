.admin{
  &.admin-pages, &.admin-menu_items{
    .editables-wrapper{
      &>.card{
        border: $black solid 2px;
        margin-bottom: 2rem;
        &>.card-header{
          background: black;
          color: #fff;
          a{
            color: #fff;
            margin-right: 1rem;
          }
          .block-title{
            text-align: center;
          }
        }
        .card-body{
          .block{
            .row{
             .attachment-container, .editable-content{
                padding: 1rem;
                box-shadow: 1px 5px 10px #aaa;
              }
              .editable-content{
                margin-bottom: 0.5rem;
              }
            }
          }
        }
      }
    }
   &.edit{
      @media (max-width: map_get($grid-breakpoints, sm)) {
        .editables-block{
          h1{
            font-size: 1.9rem;
          }
          .attachment-container{
            button{
              font-size: 0.8rem;
            }
          }
          .editables-wrapper{
            .card-body{
              padding: 0;
              .editable-content{
                & + button{
                  margin-bottom: 1rem;
                }
              }
            }
          }
        }
      }
   }
  }
}