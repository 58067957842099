@mixin card-person{
  .card{
    float: left;
    height: 24rem;
    overflow: auto;
    .card-header{
      line-height: 1rem;
      small{
        font-size: 0.8em;
      }
    }
    .card-body{
      .avatar{
        overflow: hidden;
        margin: auto;
        img{
          width: auto;
          max-width: 170px;
          max-height: 150px; //per evitare immagini troppo alte (verticali)
          display: block;
          margin: auto;

        }
      }
    }
    .card-footer{
      padding: 0;
      .table{
        margin-bottom: 0;
        td{
          text-align: center;
          padding: 0;
          a{
            padding: 0.75rem;
            display: inline-block;
            width: 100%;
            &:hover{
              text-decoration: none;
              &.show{
                background: $primary;
                color: $white;
              }
              &.edit{
                background: $gb-orange;
                color: $white;
              }
            }
          }
        }
      }
    }
    @media (max-width: map_get($grid-breakpoints, sm)) {
      width: 100%;
    }
    // Small devices (landscape phones, 576px and up)
    @media (min-width: map_get($grid-breakpoints, sm)) and (max-width:map_get($grid-breakpoints, md)) {
      width: 50%;
    }
    // Medium devices (tablets, 768px and up)
    @media (min-width: map_get($grid-breakpoints, md)) and (max-width: map_get($grid-breakpoints, lg)) {
      width: 33.3333%;
    }
    // Large devices (desktops, 992px and up)
    @media (min-width: map_get($grid-breakpoints, lg)) and (max-width: $xxl-breakpoint) {
      //column-count: 4;
      width: 25%;
    }
    @media (min-width: $xxl-breakpoint) {
      width: 20%;
    }
  }
}
@mixin level-2-cards {
  .level-2{
    .card{
      height: 26rem;
      &.giovanili{
        background: $giovanili;
        background: linear-gradient(to bottom, $form-bg, $giovanili);
      }
      &.chi-siamo{
        background: $chi-siamo;
        background: linear-gradient(to bottom, $form-bg, $chi-siamo);
      }
      &.prima-squadra{
        background: $prima-squadra;
        background: linear-gradient(to bottom, $form-bg, $prima-squadra);
      }
      &.minibasket{
        background: $minibasket;
        background: linear-gradient(to bottom, $form-bg, $minibasket);
      }
      &.news{
        background: $gb-orange;
        background: linear-gradient(to bottom, $gb-orange, $gb-blue);
      }
      &.sponsor{
        background: $sponsor;
      }
      &.with-image, &.without-image{
        .card-header{
          h3{
            font-weight: 700;
            text-align: center;
            text-shadow: 1px 1px $black;
          }
        }
        .card-body{
          //position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .subtitle{
            text-align: center;
            text-shadow: 1px 1px $black;
          }
        }
      }
      &.without-image{
        .card-body{
          .subtitle{
            line-height: 2.8rem;
            font-size: 2rem;
          }
        }
      }
    }
    a{
      .card.with-image{
        .zoom-image-container{
          overflow: hidden;
          text-align: center;
          img{
            @media (max-width: map_get($grid-breakpoints, sm)) {
              width: 100%;
            }
          }
        }
      }
    }
    a:hover{
      text-decoration: none;
      .card{
        transform: translate3d(0.1rem, 0.1rem, 0);
        z-index: 10;
        box-shadow: 4px 4px 12px 0 rgba(50,50,50,0.3);
        transition: 0.25s;
      }
      .card.with-image{
        .zoom-image-container{
          img{
            transition: 0.5s;
            transform: scale(1.2);
          }
        }
      }
    }
    @media (max-width: map_get($grid-breakpoints, sm)) {
      .card{
        height: 23rem;
        .card-header{
          font-size: 0.8rem;
        }
        h3{
          font-size: 1.2rem;
        }
        &.with-image{
          h5{
            font-size: 1rem;
          }
        }
        &.without-image{
        }
      }
    }
  }
}
@mixin cms-blocks-wrapper {
  .blocks-wrapper{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .page-date{
      align-self: flex-end;
      font-style: italic;
      padding: 0.2rem 1.5rem;
    }
    .block{
      padding: 1.5rem;
      font-size: 1.3rem;
      line-height: 2.5rem;
      width: 100%;
      h2{
        text-shadow: 1px 1px $black;
      }
      h3{
        text-shadow: 1px 1px $black;
      }
      ul{
        padding-left: 0;
        list-style-image: url('../../images/list_style_20.png');
        list-style-type: disc;
        display: flex;
        flex-direction: column;
        li{
          margin-bottom: 0.5rem;
          a{
            font-weight: 700;
          }
        }
      }
      .img-left{
        float: left;
        width: 50%;
        margin: 0 1rem 0.4rem 0;
      }
      .img-right{
        float: right;
        width: 50%;
        margin: 0 0 0.4rem 1rem;
      }
      .caption{
        text-align: center;
        font-size: 0.9rem;
        margin-top: 5px;
      }
      @media (max-width: map_get($grid-breakpoints, md)) {
        .img-left, .img-right{
          float: none;
          width: 100%;
          margin: 0 0 1rem 0;
        }
        .img-right{
          margin-top: 1rem;
        }
      }
      @media (max-width: map_get($grid-breakpoints, sm)) {
        font-size: 1rem;
        line-height: 1.5rem;
        h2{
          font-weight: 700;
          text-shadow: 1px 1px #fff;
        }
        ul{
          list-style-image: url('../../images/list_style_15.png');
          li{
            margin-bottom: 0.8rem;
            line-height: 1.1rem;
          }
        }
      }
    }
  }
}
@mixin animation-key-frames-1{
  @keyframes TOP_NAVBAR {
    from {
      top: -80px;
    }
    to {
      top: 0;
    }
  }
}