body.admin-teams{
  .photo{
    img{
      width:100%
    }
  }
  h1{
    .select-season{
      font-size: 1rem;
    }
  }
  .form-select-season{
    display: inline-block;
  }
  &.index{
    .card-teams{
      overflow: hidden;
      @include card-person;
      .team{
        height: 100%;
      }
    }
  }
  &.show{
    .players{
      img{
        max-height: 60px;
      }
    }
  }
}
