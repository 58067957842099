body.admin-players{
  .avatar{
    img{
      width:100%
    }
  }
  &.index{
    .card-players{
      overflow: hidden;
      @include card-person;
      .player{
        &.inactive{
          .card-header{
            opacity: 0.3;
            color: $black;
          }
          .card-body{
            opacity: 0.3;
          }
        }
        &.gb_playing{
          .card-header{
            background: $giovanili;
            color: #fff;
            font-weight: 700;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  form{
    .gb-player{
      .player-document{
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: $jumbotron-bg;
        .file{
          overflow: hidden;
        }
        .card{
          width: max-content;
        }
      }
    }
  }
}