.pages{
  @include cms-blocks-wrapper;
  &.index{
    .page-wrapper{
      background-image: linear-gradient(rgba(255,255,255,.88), rgba(255,255,255,.88)), url("../../../images/via_dante.jpg");
    }
  }
  &.show{
    .page-wrapper{
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #fff;
      background-image: linear-gradient(rgba(255,255,255,.85), rgba(255,255,255,.85)), url("../../../images/via_dante_full.jpg");
      background-position: center center;
      .blocks-wrapper{
        background-image: none;
        background-color: rgba(255,255,255,0.5);
        .subtitle-wrapper{
          width: 100%;
          h2{
            font-size: 2.3rem;
            text-shadow: 1px 1px $black;
            padding: 0.5rem;
            text-transform: uppercase;
          }
          .subtitle{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .block > .row > div{
          padding-bottom: 0.5rem;
        }
      }
      .external-content{
        h4{
          font-size: 1rem;
          text-align: center;
          margin-bottom: 1rem;
          img{
            margin-top: 5px;
          }
        }
        .item-block{
          border: 3px solid $form-bg;
          padding: 1rem;
          margin-bottom: 2rem;
          .date{
            text-align: right;
            font-size: 0.8rem;
            font-style: italic;
          }
          .content{
            word-break: break-word;
            .item-image{
              float: left;
              margin-right: 1rem;
              margin-bottom: 1rem;
              img{
                width: 100%;
              }
            }
          }
          .item-source{
            font-size: 1rem;
            text-align: right;
          }
        }
      }
    }
  }
  main{
    .level-2{
      .title{
        text-align: center;
        padding: 1em;
        background: #fff;
        border-top: 10px solid $gb-blue;
        margin-bottom: 10px;
        color: $gb-blue;
        text-transform: uppercase;
      }
    }
  }
  @media (max-width: map_get($grid-breakpoints, sm)) {
    main{
      .level-2{
        .title{
          font-size: 1.5rem;
          padding: 0.5em;
          margin-bottom: 0;
        }
      }
      .attachment-container{
        margin-top: 0;
      }
    }
    &.show{
      .page-wrapper{
        .blocks-wrapper{
          .subtitle-wrapper{
            h2{
              font-size: 1.8rem;
              padding: 0.4rem;
            }
          }
        }
        .external-content{
          .item-block{
            border: none;
            border-top: 3px solid $gray-400;
            padding: 0;
            padding-top: 1rem;
          }
        }
      }
    }
  }
  @media (min-width: map_get($grid-breakpoints, sm)) and (max-width:map_get($grid-breakpoints, md)) {
    main{
      .attachment-container{
        margin-top: 0;
      }
    }
    &.show{
      .page-wrapper{
        .external-content{
          .item-block{
            border: none;
            border-top: 3px solid $gray-400;
            padding: 0;
            padding-top: 1rem;
            .content{
              .item-image{
                float: none;
              }
            }
          }
        }
      }
    }
  }
}
