@import "gemonabasket/variables";
// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }
}

.rich-text-wrapper{
  max-height: 80vh;
  overflow: auto;
  border-bottom: 1rem solid $form-bg;
  trix-toolbar{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    .trix-button-group{
      margin-bottom: 0;
      .trix-button{
        background: #fff;
        font-size: 1rem;
        &.trix-active{
          background: $gb-orange;
        }
      }
    }
  }
  trix-editor{
    height: 100%;
    background: $form-bg;
    border: none;
    &.form-control{
      &:focus{
        background-color: $form-bg;
        outline: 0;
        box-shadow: none;
      }
      &.is-valid{
        background-image: none;
        border-color: transparent;
      }
    }
  }
  &.caption{
    trix-toolbar{
      .trix-button-group{
        margin-bottom: 0;
        flex-direction: row;
      }
    }
  }
}
