:root{
  font-size: 20px;
  body{
    @include level-2-cards;
    #navbar-2 {
      margin-top: 3px;
      padding-bottom: 2px;
      display: flex;
      justify-content: flex-end;
    }
    pre{
      white-space: normal;
    }
    .button-gb-logo{
      display: inline-block;
      border-radius: 0.3rem;
      border: 2px solid $gb-blue;
      img{
        border-radius: 0.3rem;
      }
    }
    .input-group{
      .input-group-append{
        cursor: pointer;
      }
    }
    table{
      tr{
        td{
          &.actions{
            text-align: right;
          }
        }
      }
    }
    header{
      &>nav{
        box-shadow: 0 1px 3px black;
        z-index: 1000;
      }
    }
    .navbar{
      @include animation-key-frames-1;
      &.fixed-top  {
        animation: TOP_NAVBAR .5s;
        animation-fill-mode: both
      }
      #navbar-links{
        font-size: 1.2rem;
      }
      .session-links{
        button{
          font-size: 1.2rem;
        }
      }
      .navbar-collapse{
        .nav-item{
          .nav-link{
            white-space: nowrap;
          }
        }
        form{
          &.search{
            .form-control:focus{
              box-shadow: none;
            }
          }
        }
      }
    }
    .menu-search{
      form{
        width: 15rem;
      }
    }
    strong{
      small{
        font-weight: inherit;
      }
    }
    .has-invalid {
      .invalid-feedback {
        display: block;
      }

      label {
        color: theme-color('danger');
      }

      .form-control {
        background: theme-color-level('danger', -10);
        border-color: theme-color('danger');
      }
    }
    .attachment-container{
      img, video{
        width: 100%;
      }
    }
    #attachments-archive{
      .modal-body{
        a{
          position: relative;
          display: inline-block;
          text-decoration: none;
          i{
            &.bg{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
              font-size: 1.5rem;
            }
          }
          video, img{
            vertical-align: middle;
            border-style: none;
          }
        }
      }
    }
    .label-value{
      label{
        font-size: 0.9em;
        font-style: italic;
        margin-right: 0.5em;
        margin-bottom: 0;
      }
      span{
        font-weight: 600;
      }
    }

    .custom-file{
      width: 20em !important;
      .custom-file-input ~ .custom-file-label::after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f093";
      }
    }

    select.boolean-select{
      width: 5em !important;
      flex: none !important;

    }

    .modal{
      z-index: 9999;
      .modal-xl{
        max-width: 1200px;
      }
    }

    .ajax-load{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99999;
    }
    main.main{
      min-height: 60vh;
      .card{
        border-radius: 0;
        .card-header{
          border-radius: 0;
        }
      }
      .attachment, .upload{
        img, video{
          width: 100%;
          height: auto;
        }
      }
      h1{
        padding-bottom: 0.8rem;
      }
    }
    .fb{
      display: flex;
      justify-content: center;
      .follow-us{
        background-color: $facebook;
        padding: 1px 5px;
        margin-left: 1rem;
        font-size: 12px;
        color: #fff;
        border-radius: 3px;
        height: 20px;
        align-self: flex-end;
      }
    }
    footer{
      background: $footer-black;
      color: #fff;
      padding-top: 1rem;
      padding-bottom: 0.3rem;
      text-align: center;
      font-size: 0.7rem;
      margin-top: 10rem;
      a{
        color: #fff;
        font-weight: bold;
        &:hover{
          color: #fff;
        }
      }
      ul{
        font-size: 0.8rem;
        li{
          &:not(:last-child){
            border-right: 1px solid #fff;
            padding-right: 0.7rem;
          }
        }
      }
    }
    &.home{
      footer{
        margin-top: 0;
      }
    }
    #modal_privacy_policy, #modal_cookie_policy{
      font-size: 0.9rem;
      z-index: 99999;
      p, h2, h3{
        color: $text-light;
      }
      h2{
        font-size: 1.8rem;
      }
      h3{
        font-size: 1.5rem;
      }
    }
    .filters{
      input[type='checkbox']{
        width: 1.2rem;
        height: 1.2rem;
      }
      .free-text{
        width: 30rem;
      }
      .card{
        overflow: visible;
      }
    }
    .form-group{
      input.date_picker{
        width: 9em;
        flex: none;
        background: #fff;
      }
      .picker__select--month,
      .picker__select--year {
        padding-top: 0;
        padding-bottom: 0;
      }
      .short{
        width: 6rem;
      }
    }
    .inscription-payments-summary{
      border-radius: 1rem;
      padding: 1rem;
      table{
        margin: 0;
        tr{
          td, th{
            padding: 5px 0;
          }
          td:last-child, th:last-child{
            text-align: right;
          }
        }
      }
    }
    @media (max-width: map_get($grid-breakpoints, sm)) {
      .form-group{
        input.date_picker{
          width: calc(100% - 49px);
        }
        .picker__box{
          padding: 0;
        }
        .picker__select--year, .picker__select--month{
          font-size: 0.8em;
        }
        .picker__select--year{
          width: 28.5%;
        }
        .picker__select--month{
          width: 43%;
        }
      }
      .navbar{
        .navbar-collapse{
          padding-left: 10px;
        }
      }
      .filters{
        .free-text{
          width: 100% !important;
        }
      }
      main{
        &.main{
          h1{
            font-size: 2rem;
            padding: 0.3rem;
          }
          h2{
            font-size: 1.6rem;
          }
          h3{
            font-size: 1.3rem;
          }
          h4{
            font-size: 1.1rem;
          }
        }
      }
      .custom-file{
        width: 100% !important;
      }
    }
    @media (min-width: map_get($grid-breakpoints, lg)) and (max-width:map_get($grid-breakpoints, xl)) {
      .navbar{
        .navbar-brand{
          margin-right: 5px;
        }
       .nav-item{
         .nav-link{
           font-size: 0.86rem;
           padding: 0 10px 0 0;
         }
       }
        form{
          &.search{
            input{
              font-size: 0.83rem;
            }
            button{
              padding: 0 10px;
            }
          }
        }
      }
      .session-links{
        .btn-group{
          button{
            padding: 3px 10px;
          }
        }
      }
    }
    @media (min-width: map_get($grid-breakpoints, sm)) and (max-width:map_get($grid-breakpoints, xl)) {
      .navbar{
        .navbar-collapse{
          padding-left: 10px;
          padding-bottom: 10px;
        }
      }
    }
    form{
      .col-form-label{
        text-align: right;
      }
    }
    .payment-status{
      padding: 0.75rem;
      border-radius: 50%;
      display: inline-block;
    }
    .img-rounded{
      border-radius: 50%;
    }
  }
}
