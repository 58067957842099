body.admin-coaches{
  .avatar{
    img{
      width:100%
    }
  }
  &.index{
    .card-coaches{
      overflow: hidden;
      @include card-person;
    }
  }

}