.admin-sponsors{
  .logo{
    img{
      width:100%
    }
  }
  &.index{
    .card-sponsors{
      overflow: hidden;
      @include card-person;
      .card{
        height: 22rem;
        &.admin{
          height: 26rem;
        }
        .avatar{
          span{
            display: block;
            height: 150px;
            margin: auto;
            line-height: 150px;
            text-align: center;
            background: $form-bg;
            font-style: italic;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
.sponsor-wrapper{
  min-width: 60%;
  .sponsor-promo{
    h2{
      text-shadow: 1px 1px #fff;
      font-weight: bold;
    }
    h3{
      text-shadow: 1px 1px #fff;
    }
    .offer-validity{
      margin-top: 1rem;
      padding: 0.5rem;
      border-style: groove;
      border-color: $green;
      border-width: 5px;
      border-radius: 5px;
      margin-bottom: 1rem;
      h5{
        margin: 0;
        text-align: center;
        font-weight: 700;
        text-shadow: 1px 1px #fff;
        width: 100%;
      }
    }
  }
  .level-1{
    .card-header{
      background-color: #fff;
      h1{
        color: $gb-blue;
        text-shadow: 1px 0px $black;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        &:hover{
        text-shadow: 1px 1px $black;
        }
      }
    }
  }
  @media (max-width: map_get($grid-breakpoints, sm)) {
    .level-1{
      h1{
        font-size: 2rem;
      }
      .card-header{
        h1{
          font-size: 1.3rem;
        }
      }
    }
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: map_get($grid-breakpoints, sm)) and (max-width:map_get($grid-breakpoints, md)) {
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: map_get($grid-breakpoints, md)) and (max-width: map_get($grid-breakpoints, lg)) {
  }
}
